// /* @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800'); */
// @import '~antd/lib/style/themes/default.less';

// .basicpage{
// 	// background: linear-gradient(@primary-color, @primary-color);
// 	// /* animation: mymove 10s infinite; */
// 	// animation-fill-mode: forwards;
// 	// background-size: 400% 400%;
// 	// padding: 0 15px
// 	background: #0d0d39;
// background: linear-gradient(180deg, #0d0d39 0%, #24234f 50%, #33314f 100%);
// }

// .OTPInput{
// 	padding-left: 15px;
//     letter-spacing: 60px;
//     border: 0;
//     //background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
//     background-position: bottom;
//     background-size: 70px 3px;
//     background-repeat: repeat-x;
//     background-position-x: 40px;
// }

// /* @-webkit-keyframes mymove {
// 	0%{background-position:51% 0%}
//     50%{background-position:50% 100%}
//     100%{background-position:51% 0%}
// }

// @keyframes mymove {	
// 	0%{background-position:51% 0%}
//     50%{background-position:50% 100%}
//     100%{background-position:51% 0%}	
// } */

// .logo{max-width: 80px; margin: 0 auto; text-align: center; margin-bottom: 25px;}
// .basicbox{
// 	background: rgba(255,255,255,1);
// 	border-radius: 40px;
// 	overflow: hidden;
// 	width:100%;
// 	max-width: 750px;
// 	display: flex; justify-content: space-around; align-items: center;
// 	box-shadow: 0px 0px 10px lighten(#fffefe,35%);
// }


// form{width:100%; max-width:290px}
// .mainimg{height: 100%;
// 	// background-image: url(../public/static/images/banner.png);
// 	background-image: url(../public/static/images/kbyg_logo.jpg);
// 	background-size: cover; position: relative;
// 	background-position: center; }
// .registerimg{ background-image: url(../public/static/images/kbyg_logo.jpg);}
// .resetimg{background-image: url(https://images.unsplash.com/1/bag-and-hands.jpg?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=e67590234d9417585f3717e8fd804add&auto=format&fit=crop&w=747&q=80);}
// .mainimg:after{content: '';
//     position: absolute;
//     height: 100%;
//     width: 100%;
//     background: rgba(238,58,41,0.2);}
// .mainform{height: 100%;  padding:25px; min-height: 200px; width:100%;     display: flex;
//     justify-content: center;
// 	align-items: center;     flex-flow: column;}
// .ant-form-item-explain, .ant-form-item-extra {
//     font-size: 10px !important;
// }
// .eyeicon img { max-width: 15px; opacity: 0.4;}
// .PassPattern{margin: 0; font-size: 0.7rem;  list-style: none}
// .PassPattern .active{color:#6ad834}
// /* .ant-tooltip-content .ant-tooltip-arrow{border-bottom-color:rgb(208, 190, 190)} */
// /* .ant-tooltip-inner{background: rgb(208, 190, 190)} */

// @media screen and (max-width:600px){
// 	.mainimg{display: none}
// }


// .responsiveTable {
// 	width: 100%; overflow-x: auto;
// 	.ant-table-content{overflow-y:auto}
// }
// .responsiveTable .ant-table-tbody > tr > td{min-width:200px; max-width:50%}


/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800'); */
@import '~antd/lib/style/themes/default.less';

.basicpage{
	// background: linear-gradient(@primary-color, @primary-color);
	// /* animation: mymove 10s infinite; */
	// animation-fill-mode: forwards;
	// background-size: 400% 400%;
	// padding: 0 15px
	background: #b8d4e2;
background: linear-gradient(180deg, #e3e0f1 0%, #b8d4e2 50%, #fefefe 100%);
}

.OTPInput{
	padding-left: 15px;
    letter-spacing: 60px;
    border: 0;
    //background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 70px 3px;
    background-repeat: repeat-x;
    background-position-x: 40px;
}

/* @-webkit-keyframes mymove {
	0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}

@keyframes mymove {	
	0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}	
} */

.logo{max-width: 80px; margin: 0 auto; text-align: center; margin-bottom: 25px;}
.basicbox{
	background: rgba(255,255,255,1);
	border-radius: 40px;
	overflow: hidden;
	width:100%;
	max-width: 750px;
	display: flex; justify-content: space-around; align-items: center;
	box-shadow: 0px 0px 10px lighten(#fffefe,35%);
}


form{width:100%; max-width:290px}
.mainimg{height: 100%;     
	background-image: url(https://media.istockphoto.com/id/1465157700/photo/brightly-red-colored-semi-truck-speeding-on-a-two-lane-highway-with-cars-in-background-under.webp?b=1&s=170667a&w=0&k=20&c=sPYVxhIZwoQ9__O8KukZ5eILdhzSMvwUYrS3n11FGx4=);
	background-size: cover; position: relative;
	background-position: center; }
.registerimg{ background-image: url(https://media.istockphoto.com/id/1465157700/photo/brightly-red-colored-semi-truck-speeding-on-a-two-lane-highway-with-cars-in-background-under.webp?b=1&s=170667a&w=0&k=20&c=sPYVxhIZwoQ9__O8KukZ5eILdhzSMvwUYrS3n11FGx4=);}
.resetimg{background-image: url(https://images.unsplash.com/1/bag-and-hands.jpg?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=e67590234d9417585f3717e8fd804add&auto=format&fit=crop&w=747&q=80);}
.mainimg:after{content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(17, 151, 151, 0.2);}
.mainform{height: 100%;  padding:25px; min-height: 200px; width:100%;     display: flex;
    justify-content: center;
	align-items: center;     flex-flow: column;}
.ant-form-item-explain, .ant-form-item-extra {
    font-size: 10px !important;
}
.eyeicon img { max-width: 15px; opacity: 0.4;}
.PassPattern{margin: 0; font-size: 0.7rem;  list-style: none}
.PassPattern .active{color:#6ad834}
/* .ant-tooltip-content .ant-tooltip-arrow{border-bottom-color:rgb(208, 190, 190)} */
/* .ant-tooltip-inner{background: rgb(208, 190, 190)} */

@media screen and (max-width:600px){
	.mainimg{display: none}
}


.responsiveTable {
	width: 100%; overflow-x: auto;
	.ant-table-content{overflow-y:auto}
}
.responsiveTable .ant-table-tbody > tr > td{min-width:200px; max-width:50%}



@primary-color: rgba(252, 184, 0, 1);@link-color: rgba(252, 184, 0, 1);@error-color: #f14705;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@input-hover-border-color: #d9d9d9;@success-color: #690;@warning-color: rgba(252, 184, 0, 1);@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .75);@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;